var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "editable": "",
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "name",
      "readonly": _vm.readonly,
      "label": "Kostnadstype",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på kostnaden.';
      }],
      "required": ""
    },
    model: {
      value: _vm.costModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "name", $$v);
      },
      expression: "costModel.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "price",
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Sats",
      "suffix": " kr",
      "rules": [_vm.validateNonNegativeNumber],
      "required": ""
    },
    model: {
      value: _vm.costModel.price,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "price", $$v);
      },
      expression: "costModel.price"
    }
  })], 1), _vm.costName == _vm.ExpenseType.SalaryRoute ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.costTypesSalaryCost,
      "readonly": _vm.readonly,
      "label": "Kategori",
      "item-text": function itemText(item) {
        return "".concat(item.id, " - ").concat(item.name);
      },
      "item-value": "id",
      "rules": [function (v) {
        return !!v || 'Velg kategori';
      }]
    },
    model: {
      value: _vm.costModel.costTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "costTypeId", $$v);
      },
      expression: "costModel.costTypeId"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "deductionCode",
      "readonly": _vm.readonly,
      "label": "Trekkode"
    },
    model: {
      value: _vm.costModel.deductionCode,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "deductionCode", $$v);
      },
      expression: "costModel.deductionCode"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "payType",
      "readonly": _vm.readonly,
      "label": "Lønnsart",
      "rules": [function (v) {
        return !!v || 'Feltet er påkrevd.';
      }, function (v) {
        return /^\d{4}$/.test(v) || 'Lønnsart skal bestå av 4 siffer. ';
      }],
      "required": ""
    },
    model: {
      value: _vm.costModel.payType,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "payType", $$v);
      },
      expression: "costModel.payType"
    }
  })], 1) : _vm._e(), _vm.costName == _vm.ExpenseType.ExpenseRoute ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.costTypesExpenses,
      "readonly": _vm.readonly,
      "label": "Kategori",
      "item-text": function itemText(item) {
        return "".concat(item.id, " - ").concat(item.name);
      },
      "item-value": "id",
      "rules": [function (v) {
        return !!v || 'Velg kategori';
      }]
    },
    model: {
      value: _vm.costModel.costTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "costTypeId", $$v);
      },
      expression: "costModel.costTypeId"
    }
  }), _vm.costModel.costTypeId === 512 ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.travelAndExpenseTypes,
      "readonly": _vm.readonly,
      "rules": [function (v) {
        return !!v || 'Velg utgiftstype';
      }],
      "label": "Utgiftstype"
    },
    model: {
      value: _vm.costModel.travelAndExpenseType,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "travelAndExpenseType", $$v);
      },
      expression: "costModel.travelAndExpenseType"
    }
  }) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "items": _vm.teachingMethodsList,
      "readonly": _vm.readonly,
      "label": "Undervisningsform"
    },
    model: {
      value: _vm.costModel.teachingMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "teachingMethod", $$v);
      },
      expression: "costModel.teachingMethod"
    }
  }), _c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Artikkelnummer",
      "rules": [function (v) {
        return !!v || 'Feltet er påkrevd';
      }]
    },
    model: {
      value: _vm.costModel.articleNo,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "articleNo", $$v);
      },
      expression: "costModel.articleNo"
    }
  }), _c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Beskrivelse",
      "rules": [function (v) {
        return !!v || 'Fyll inn beskrivelse på kostnaden.';
      }]
    },
    model: {
      value: _vm.costModel.description,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "description", $$v);
      },
      expression: "costModel.description"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "data-cy": "isActive",
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "rules": [function (v) {
        return /(?:true|false)/.test(v) || 'Velg emnetype.';
      }],
      "label": _vm.costModel.isActive ? 'Er aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.costModel.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.costModel, "isActive", $$v);
      },
      expression: "costModel.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }